import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout/Layout"
import FlexibleContent from "components/FlexibleContent/FlexibleContent"

const DefaultTemplate = (props) =>  {
  const data = props.data.wpPage
  console.log(data)
  const acfBlocks = data.template.acfFlexibleContent.blocks
  return (
    <Layout 
      path={data.uri} 
      seo={data.seo}
      pageId={data.id}
    >
      <FlexibleContent blocks={acfBlocks} pageTitle={data.title} seo={data.seo} />
    </Layout>
  )
}

export const pageQuery = graphql`query($id: String!) {
  wpPage(id: {eq: $id}) {
    id
    title
    uri
    seo {
      title
      metaDesc
      metaKeywords
      breadcrumbs {
        text
        url
      }
    }
    template {
      ... on WpDefaultTemplate {
        ...defaultTemplateQuery
      }
    }
  }
}`

export default DefaultTemplate